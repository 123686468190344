import './Form.css';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from "../context/auth";
import decode from 'jwt-decode';
import axios from 'axios';
import Table from '../components/Table';
import Select from 'react-select';
import InputMask from "react-input-mask";

const API_URL = process.env.REACT_APP_API_URL;

function RecipientForm() {

    // const [internalServerError, setInternalServerError] = useState(false);
    const { authTokens, setAuthTokens } = useAuth();
    const [agency, setAgency] = useState();
    const [agencies, setAgencies] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [formTitle, setFormTitle] = useState("Add New Recipient");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [recipientId, setRecipientId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [recognition, setRecognition] = useState("");
    const [submitterName, setSubmitterName] = useState("");
    const [submitterEmail, setSubmitterEmail] = useState("");
    const [submitterPhone, setSubmitterPhone] = useState("");
    const [isTouchedAgency, setIsTouchedAgency] = useState(false);
    const [isTouchedFirstName, setIsTouchedFirstName] = useState(false);
    const [isTouchedlastName, setIsTouchedlastName] = useState(false);
    const [isTouchedrecognition, setIsTouchedRecognition] = useState(false);
    const [isTouchedsubmitterEmail, setIsTouchedSubmitterEmail] = useState(false);
    const [isTouchedsubmitterName, setIsTouchedSubmitterName] = useState(false);
    const [isTouchedsubmitterPhone, setIsTouchedSubmitterPhone] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [formHeight, setFormHeight] = useState(50);
    const [isLocked, setIsLocked] = useState(false);
    const [accordianDirection, setAccordianDirection] = useState(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    
    const useMountEffect = (fun) => useEffect((fun), []);

    const columns = [
        // {
        //     Header: '',
        //     accessor: 'edit',
        //     Cell: ({row}) => (
        //     <div>{!isLocked && <button onClick={() => getRecipients(row.original.recipientId)}>Edit</button>}</div>
        //     )
        // },
        {
            Header: 'Agency',
            accessor: 'agencyName'
        },
        {
            Header: 'First Name',
            accessor: 'firstName'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName'
        },
        {
            Header: 'Recognition',
            accessor: 'recognition'
        },
        {
            Header: 'Submitter Name',
            accessor: 'submitterName'
        },
        {
            Header: 'Submitter Email',
            accessor: 'submitterEmail'
        },
        {
            Header: 'Submitter Phone',
            accessor: 'submitterPhone'
        },
    ];

    const dec = authTokens ? decode(authTokens.token).exp : '';
    const dtn = Date.now() / 1000;

    const formExpandedRef = useRef();
    const formCollapsedStyle = {height: `${formHeight}px`};

    function logOut() {
        setAuthTokens();
        localStorage.clear();
    }

    function validateForm(firstName, lastName, recognition, submitterName, submitterEmail, submitterPhone) {
        return {
            firstName: firstName.length === 0,
            lastName: lastName.length === 0,
            recognition: recognition.length === 0,
            submitterName: submitterName.length === 0,
            submitterEmail: submitterEmail.length === 0,
            submitterPhone: submitterPhone.length === 0
        }
    };

    function clearForm() {
        setFormTitle("Add New Recipient");
        setRecipientId("");
        setFirstName("");
        setIsTouchedFirstName(false);
        setLastName("");
        setIsTouchedlastName(false);
        setRecognition("");
        setIsTouchedRecognition(false);
        setSubmitterEmail("");
        setIsTouchedSubmitterEmail(false);
        setSubmitterName("");
        setIsTouchedSubmitterName(false);
        setSubmitterPhone("");
        setIsTouchedSubmitterPhone(false);
    }   
    
    function shouldMarkError(hasError, isTouched) {
        return hasError ? isTouched : false;
    };

    const errors = validateForm(firstName, lastName, recognition, submitterName, submitterEmail, submitterPhone);

    function hasErrors()
    {
        return errors["firstName"] || errors["lastName"] || errors["recognition"] || errors["submitterEmail"] || errors["submitterName"] || errors["submitterPhone"];
    }

    function getAgencies() {
        const url = `${API_URL}/agencies`;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        
        axios.get(url, config).then(response => response.data)
            .then((data) => {
                    const options = data.map(d => ({
                    "value" : d.agencyName,
                    "label" : d.agencyName
                    }))
                    setAgencies(options);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else if (error.request) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
            });
    }


    function updateRecipient() {
        const url = `${API_URL}/recipients`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        const data = {
            'recipientId':recipientId,
            'agencyName':agency,
            'firstName':firstName,
            'lastName':lastName,
            'recognition':recognition,
            'submitterName':submitterName,
            'submitterEmail':submitterEmail,
            'submitterPhone':submitterPhone};
        
        if (dec > dtn) {
            axios.put(url, data, config).then(response => response.data)
            .then((data) => {
                setIsSuccess(true);
                getRecipients();
                clearForm();
                setTimeout(() => {setIsSuccess(false)}, 5000);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else if (error.request) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
            });
        }
        else {
            logOut();
        }
    }

    function insertRecipient() {
        const url = `${API_URL}/recipients`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        const data = {
            'agencyName':agency,
            'firstName':firstName,
            'lastName':lastName,
            'recognition':recognition,
            'submitterName':submitterName,
            'submitterEmail':submitterEmail,
            'submitterPhone':submitterPhone};
        
        if (dec > dtn) {
            axios.post(url, data, config).then(response => response.data)
            .then((data) => {
                setIsSuccess(true);
                getRecipients();
                clearForm();
                setTimeout(() => {setIsSuccess(false)}, 5000);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else if (error.request) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
            });
        }
        else {
            logOut();
        }
    }

    function cancelSubmit(e){
        e.preventDefault();
        clearError();
        clearForm();
    }

    function submitRecipient(e){
        e.preventDefault();
        clearError();
        if (recipientId){
            updateRecipient();
        }
        else {
            insertRecipient();
        }
    }

    function mapRecipient(recipient){
        setRecipientId(recipient.recipientId);
        setFirstName(recipient.firstName)
        setLastName(recipient.lastName);
        setRecognition(recipient.recognition);
        setSubmitterEmail(recipient.submitterEmail);
        setSubmitterName(recipient.submitterName);
        setSubmitterPhone(recipient.submitterPhone);
    }

    function getRecipients(id) {
        const endpoint = id ? '/' + id : agency ? '?agencyName=' + agency : '';
        const url = `${API_URL}/recipients${endpoint}`;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + authTokens.token
            }
        }
        if(dec > dtn) {
            axios.get(url, config).then(response => response.data)
                .then((data) => {
                    if (id) {
                        mapRecipient(data);
                        setFormTitle("Edit Recipient");
                        if (!showForm){
                            toggleForm();
                        }
                    }
                    else {
                        setRecipients(data);
                        setFormTitle("Add New Recipient");
                    }
                }).catch(error => {
                    setIsError(true);
                    if (error.response) {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                    else if (error.request) {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                    else {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                });
        }
        else {
            logOut();
        }
    }

    function clearError() {
        setIsError(false);
        setErrorMessage("");
    }

    function toggleForm() {
        if(showForm){
            setFormHeight(formExpandedRef.current.clientHeight);
        }
        showForm ? setShowForm(false) : setShowForm(true);
        showForm ? setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-right']} />) : setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    }   

    const reactSelectStyles = {
        container: (provided, state) => ({
            ...provided,
            padding: 0,
            height: 'fit-content',
            zIndex: 4,
            border: '1px solid gray',
            width: '320px'
        }),
        control: (provided, state) => ({
            ...provided,
            borderWidth: 0,
            minHeight: 'fit-content',
            height: 'fit-content',
            fontSize: '0.85em'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '2em'
        }),
        input: (provided, state) => ({
            ...provided,
            height: '1.6em'
        })
    };

    // const reactSelectStyles = {
    //     control: base => ({
    //         ...base,
    //         height: '35px',
    //         minHeight:'35px',
    //         borderColor: 'cdccdf',
    //         fontSize: '0.8em',
    //         fontFamily: 'Avenir, Arial, Helvitica, sans-serif',
    //         width: '320px'
    //     }),
    //     menu: (base) => ({
    //         ...base,
    //         height: '35px',
    //         minHeight:'35px',
    //         fontSize: '0.8em',
    //         fontFamily: 'Avenir, Arial, Helvitica, sans-serif',
    //         marginTop: '0px'
    //     }),
    // };

    useMountEffect(getAgencies);
    useMountEffect(getRecipients);

    return (
        <div>
            {isError && <div className="error-message white-background">{errorMessage}</div>}
            {!isError && <div className="full-row">{errorMessage}</div>}
            {!isLocked && showForm &&
            <form className="form" onSubmit={submitRecipient}>       
                <ul className="form-wrapper" ref={node => formExpandedRef.current = node}>
                    <li className="form-header">
                        <label>{formTitle}</label>
                        <div className="accordian" onClick={toggleForm}>{accordianDirection}</div>
                    </li>
                    <li className="form-info-message">
                        {isSuccess ? <div className="info-message">Recipient successfully submitted!</div> : <div className="infoMessage">&nbsp;</div>}
                    </li>
                    <li className="form-row">
                        {/* <label>Agency</label> */}
                        <div className="form-fieldset">
                            <Select 
                                name="agency"
                                className={shouldMarkError(errors["agency"], isTouchedAgency) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedAgency(true)}
                                onChange={e => {setAgency(e.value);}}
                                options={agencies} 
                                styles={reactSelectStyles}
                                placeholder="Select agency"
                            />
                            {shouldMarkError(errors["agency"], isTouchedAgency) ? <div className="error-message">*</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>First name</label> */}
                        <div className="form-fieldset">
                            <input
                                name="firstName"
                                className={shouldMarkError(errors["firstName"], isTouchedFirstName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedFirstName(true)}
                                type="text"
                                maxLength="50"
                                value={firstName}
                                onChange={e => {setFirstName(e.target.value);}}
                                placeholder="First name"
                            />
                            {shouldMarkError(errors["firstName"], isTouchedFirstName) ? <div className="error-message">*</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Last name</label> */}
                        <div className="form-fieldset">
                            <input
                                name="lastName"
                                className={shouldMarkError(errors["lastName"], isTouchedlastName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedlastName(true)}
                                type="text"
                                maxLength="50"
                                value={lastName}
                                onChange={e => {setLastName(e.target.value);}}
                                placeholder="Last name"
                            />
                            {shouldMarkError(errors["lastName"], isTouchedlastName) ? <div className="error-message">*</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Recognition</label> */}
                        <div className="form-fieldset">
                            <textarea
                                name="recognition"
                                className={shouldMarkError(errors["recognition"], isTouchedrecognition) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedRecognition(true)}
                                value={recognition}
                                onChange={e => {setRecognition(e.target.value);}}
                                placeholder="Enter your nomination reasons here..."
                            />
                            {shouldMarkError(errors["recognition"], isTouchedrecognition) ? <div className="error-message">*</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Your name</label> */}
                        <div className="form-fieldset">
                            <input
                                name="submitterName"
                                className={shouldMarkError(errors["submitterName"], isTouchedsubmitterName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedSubmitterName(true)}
                                type="text"
                                maxLength="50"
                                value={submitterName}
                                onChange={e => {setSubmitterName(e.target.value);}}
                                placeholder="Submitter name"
                            />
                            {shouldMarkError(errors["submitterName"], isTouchedsubmitterName) ? <div className="error-message">*</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Your email</label> */}
                        <div className="form-fieldset">
                            <input
                                name="submitterEmail"
                                className={shouldMarkError(errors["submitterEmail"], isTouchedsubmitterEmail) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedSubmitterEmail(true)}
                                type="text"
                                maxLength="50"
                                value={submitterEmail}
                                onChange={e => {setSubmitterEmail(e.target.value);}}
                                placeholder="Submitter email"
                            />
                            {shouldMarkError(errors["submitterEmail"], isTouchedsubmitterEmail) ? <div className="error-message">*</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Your phone</label> */}
                        <div className="form-fieldset">
                            <InputMask 
                                name="submitterPhone"
                                className={shouldMarkError(errors["submitterPhone"], isTouchedsubmitterPhone) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedSubmitterPhone(true)}
                                mask="999-999-9999"
                                value={submitterPhone}
                                onChange={e => {setSubmitterPhone(e.target.value);}}
                                required
                                placeholder="Submitter phone"
                            />                            
                            {shouldMarkError(errors["submitterPhone"], isTouchedsubmitterPhone) ? <div className="error-message">*</div> : <div className="error-message"></div>}                            
                        </div>
                    </li>
                    <li className="form-row">
                        <div className="button-row">
                            <button type="button" onClick={cancelSubmit}>Cancel</button>
                            <button type="submit" disabled={hasErrors()}>Submit</button>
                        </div>
                    </li>
                    <li className="form-row" />
                </ul>
            </form>
            }
            <Table columns={columns} data={recipients} />
        </div>
    )
}

export default RecipientForm;