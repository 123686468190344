import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Home from './Pages/Home';
import Admin from './Pages/Admin';
import Login from './Pages/Login';
import { AuthContext } from './context/auth';
import { UserContext } from './context/user';
import { NavContext } from './context/nav';
import NotFound from './ErrorPages/NotFound';
import InternalServer from './ErrorPages/InternalServer'

function App() {

  const existingTokens = JSON.parse(localStorage.getItem("tokens"));
  const [authTokens, setAuthTokens] = useState(existingTokens);

  const existingAgencies = JSON.parse(localStorage.getItem("agency"));
  const [agencies, setAgencies] = useState(existingAgencies);

  const [showAdminMenu, setShowAdminMenu] = useState(true);
  const adminMenuValue = [showAdminMenu, setShowAdminMenu]

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
  }

  const setAgency = (data) => {
    localStorage.setItem("agency", JSON.stringify(data));
    setAgencies(data);
  }

  return (

    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <UserContext.Provider value={{ agencies, setAgencies: setAgency }}>
        <NavContext.Provider value={adminMenuValue}>
        <Router>
          <Switch>
            <PrivateRoute exact path="/admin" component={Admin} />
            <PrivateRoute path="/admin" component={Admin} />
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/500" component={InternalServer} />
            <Route component={NotFound} /> 
          </Switch>
        </Router>
        </NavContext.Provider>
      </UserContext.Provider>
    </AuthContext.Provider>

  );

}

export default App;
