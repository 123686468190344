import './Form.css';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from "../context/auth";
import InputMask from "react-input-mask";
import Select from 'react-select';
import decode from 'jwt-decode';
import axios from 'axios';
import Table from '../components/Table';

const API_URL = process.env.REACT_APP_API_URL;

function UserForm() {

    const { authTokens, setAuthTokens } = useAuth();
    const [users, setUsers] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailError, setEmailError] = useState("");
    const [userId, setUserId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [title, setTitle] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [isTouchedFirstName, setIsTouchedFirstName] = useState(false);
    const [isTouchedLastName, setIsTouchedLastName] = useState(false);
    const [isTouchedTitle, setIsTouchedTitle] = useState(false);
    const [isTouchedPhone, setIsTouchedPhone] = useState(false);
    const [isTouchedEmail, setIsTouchedEmail] = useState(false);
    // const [isTouchedUsername, setIsTouchedUsername] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [formHeight, setFormHeight] = useState(50);
    const [isEdit, setIsEdit] = useState(false);
    const [accordianDirection, setAccordianDirection] = useState(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    
    const useMountEffect = (fun) => useEffect((fun), []);

    const columns = [
        {
            Header: '',
            accessor: 'edit',
            Cell: ({row}) => (
            <div><button onClick={() => { setIsEdit(true); mapUser(row.original); }}>Edit</button></div>
            )
        },
        {
            Header: 'First Name',
            accessor: 'firstName'
        },
        {
            Header: 'Last Name',
            accessor: 'lastName'
        },
        {
            Header: 'Title',
            accessor: 'title'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        // {
        //     Header: 'Username',
        //     accessor: 'username'
        // },
        {
            id: 'disabled',
            Header: 'Disabled',
            accessor: 'disabled',
            Cell: ({row}) => (
                <div className='center'>{row.original.disabled ? 'x' : ''}</div>
            )
        },
    ];

    const dec = decode(authTokens.token).exp;
    const dtn = Date.now() / 1000;

    const formExpandedRef = useRef();
    const formCollapsedStyle = {height: `${formHeight}px`};

    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

    // const reactSelectStyles = {
    //     container: (provided, state) => ({
    //         ...provided,
    //         padding: 0,
    //         height: 'fit-content',
    //         zIndex: 4,
    //         border: '1px solid gray'
    //     }),
    //     control: (provided, state) => ({
    //         ...provided,
    //         borderWidth: 0,
    //         minHeight: 'fit-content',
    //         height: 'fit-content',
    //         fontSize: '0.85em'
    //     }),
    //     indicatorsContainer: (provided, state) => ({
    //         ...provided,
    //         height: '2em'
    //     }),
    //     input: (provided, state) => ({
    //         ...provided,
    //         height: '1.6em'
    //     })
    // };

    function logOut() {
        setAuthTokens();
        localStorage.clear();
    }

    function clearForm() {
        setIsEdit(false);
        setFirstName("");
        setIsTouchedFirstName(false);
        setLastName("");
        setIsTouchedLastName(false);
        setTitle("");
        setIsTouchedTitle(false);
        setPhone("");
        setIsTouchedPhone(false);
        setEmail("");
        setIsTouchedEmail(false);
        // setUsername("");
        // setIsTouchedUsername(false);
        setDisabled(false);
    }   
    
    function shouldMarkError(hasError, isTouched) {
        return hasError ? isTouched : false;
    };

    function emailExists(email) {
        var existingUser = users.filter(user => {
            return user.email === email;
        }).map(function(u){
            return u.userId;
        });

        if(existingUser.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    function validateForm(firstName, lastName, title, phone, email, username, disabled) {
        var emailError; 
        var emailIsError = false;
        
        if(email.length === 0){
            emailError = "Email is required"; 
            emailIsError = true;
        }
        else if(!validEmail.test(email)){
            emailError =  "Invalid email address"; 
            emailIsError = true;
        }
        else if(!isEdit && emailExists(email)) {
            emailError = "Email already exists";
            emailIsError = true;
        }

        return {
            firstName: {isError: firstName.length === 0, error: "*"},
            lastName: {isError: lastName.length === 0, error: "*"},
            title: {isError: title.length === 0, error: "*"},
            phone: {isError: phone.replace(/_/g, "").length !== 12, error: "*"},
            email: {isError: emailIsError, error: emailError},
        }
    };

    const errors = validateForm(firstName, lastName, title, phone, email);

    function hasErrors()
    {
        return errors["firstName"].isError || errors["lastName"].isError || errors["title"].isError || errors["phone"].isError || errors["email"].isError;
    }

    function updateUser() {
        const url = `${API_URL}/users`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        const data = {
            "userId":userId,
            'firstName':firstName,
            'lastName':lastName,
            'title':title,
            'phone':phone,
            'email':email,
            'username':username,
            'disabled':disabled
        };

        console.log(data);
        
        if (dec > dtn) {
            axios.put(url, data, config).then(response => response.data)
            .then((data) => {
                setIsSuccess(true);
                getUsers();
                clearForm();
                setTimeout(() => {setIsSuccess(false)}, 5000);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    // console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else if (error.request) {
                    // console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else {
                    // console.log(error.stack);
                    setErrorMessage(error.message);
                }
            });
        }
        else {
            logOut();
        }
    }

    function insertUser() {
        const url = `${API_URL}/users`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        const data = {
            'firstName':firstName,
            'lastName':lastName,
            'title':title,
            'phone':phone,
            'email':email,
            'username':username,
            'disabled':disabled
        };
        
        if (dec > dtn) {
            axios.post(url, data, config).then(response => response.data)
            .then((data) => {
                setIsSuccess(true);
                getUsers();
                clearForm();
                setTimeout(() => {setIsSuccess(false)}, 5000);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    // console.log(error.response.data);
                    setErrorMessage(error.response.data.title);
                }
                else if (error.request) {
                    // console.log(error.request);
                    setErrorMessage(error.request.data.title);
                }
                else {
                    // console.log(error);
                    setErrorMessage(error.message);
                }
            });
        }
        else {
            logOut();
        }
    }

    function cancelSubmit(e){
        e.preventDefault();
        clearError();
        clearForm();
    }

    function submitUser(e){
        e.preventDefault();
        clearError();
        if (isEdit){
            updateUser();
        }
        else {
            insertUser();
        }
    }

    function mapUser(user){
        setUserId(user.userId);
        setFirstName(user.firstName)
        setLastName(user.lastName);
        setTitle(user.title);
        setPhone(user.phone);
        setEmail(user.email);
        setUsername(user.username);
        setDisabled(user.disabled);
    }

    function getUsers(id) {
        const endpoint = id ? '/' + id : '';
        const url = `${API_URL}/users${endpoint}`;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + authTokens.token
            }
        }
        if(dec > dtn) {
            axios.get(url, config).then(response => response.data)
                .then((data) => {
                    if (id) {
                        mapUser(data);
                        if (!showForm){
                            toggleForm();
                        }
                    }
                    else {
                        setUsers(data);
                    }
                }).catch(error => {
                    setIsError(true);
                    if (error.response) {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                    else if (error.request) {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                    else {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                });
        }
        else {
            logOut();
        }
    }

    function clearError() {
        setIsError(false);
        setErrorMessage("");
    }

    function toggleForm() {
        if(showForm){
            setFormHeight(formExpandedRef.current.clientHeight);
        }
        showForm ? setShowForm(false) : setShowForm(true);
        showForm ? setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-right']} />) : setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    }   

    useMountEffect(getUsers);

    return (
        <div>
            {isError && <div className="error-message white-background">{errorMessage}</div>}
            {!isError && <div className="full-row">{errorMessage}</div>}
            <form className="form" onSubmit={submitUser}>
                {!showForm &&
                <ul className="form-wrapper-collapsed" style={formCollapsedStyle}>
                    <li className="form-header-collapsed">
                        <label>&nbsp;</label>
                        <div className="accordian" onClick={toggleForm}>{accordianDirection}</div>
                    </li>
                    <li className="form-row-collapsed">
                    </li>
                </ul>
                }
                {showForm && 
                <ul className="form-wrapper" ref={node => formExpandedRef.current = node}>
                    <li className="form-header">
                        {isEdit && <label>Edit User</label>}
                        {!isEdit && <label>Add New User</label>}
                        <div className="accordian" onClick={toggleForm}>{accordianDirection}</div>
                    </li>
                    <li className="form-info-message">
                        {isSuccess ? <div className="info-message">User successfully submitted!</div> : <div className="infoMessage">&nbsp;</div>}
                    </li>
                    <li className="form-row">
                        {/* <label>First Name:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="firstName"
                                className={shouldMarkError(errors["firstName"].isError, isTouchedFirstName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedFirstName(true)}
                                type="text"
                                maxLength="100"
                                value={firstName}
                                onChange={e => {setFirstName(e.target.value);}}
                                placeholder="First Name"
                            />
                            {shouldMarkError(errors["firstName"].isError, isTouchedFirstName) ? <div className="error-message">{errors["firstName"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Last Name:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="lastName"
                                className={shouldMarkError(errors["lastName"].isError, isTouchedLastName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedLastName(true)}
                                type="text"
                                maxLength="100"
                                value={lastName}
                                onChange={e => {setLastName(e.target.value);}}
                                placeholder="Last Name"
                            />
                            {shouldMarkError(errors["lastName"].isError, isTouchedLastName) ? <div className="error-message">{errors["lastName"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Title:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="title"
                                className={shouldMarkError(errors["title"].isError, isTouchedTitle) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedTitle(true)}
                                type="text"
                                maxLength="50"
                                value={title}
                                onChange={e => {setTitle(e.target.value);}}
                                placeholder="Title"
                            />
                            {shouldMarkError(errors["title"].isError, isTouchedTitle) ? <div className="error-message">{errors["title"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Phone:</label> */}
                        <div className="form-fieldset">
                            <InputMask
                                name="phone"
                                className={shouldMarkError(errors["phone"].isError, isTouchedPhone) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedPhone(true)}
                                mask="999-999-9999"
                                value={phone}
                                onChange={e => {setPhone(e.target.value);}}
                                placeholder="Phone"
                            />
                            {shouldMarkError(errors["phone"].isError, isTouchedPhone) ? <div className="error-message">{errors["phone"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Email:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="email"
                                // disabled={isEdit}
                                className={shouldMarkError(errors["email"].isError, isTouchedEmail) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedEmail(true)}
                                type="text"
                                value={email}
                                onChange={e => {setEmail(e.target.value);}}
                                placeholder="Email"
                            />
                            {shouldMarkError(errors["email"].isError, isTouchedEmail) ? <div className="error-message">{errors["email"].error}</div> : emailError ? <div className="error-message">Email already exists</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    {/* <li className="form-row">
                        <label>
                            Username:
                        </label>
                        <div className="form-fieldset">
                            <input
                                name="username"
                                className={shouldMarkError(errors["username"].isError, isTouchedUsername) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedUsername(true)}
                                type="number"
                                maxLength="5"
                                value={username}
                                onChange={e => {setUsername(e.target.value);}}
                                placeholder="Username"
                            />
                            {shouldMarkError(errors["username"].isError, isTouchedUsername) ? <div className="error-message">{errors["username"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li> */}
                    <li className="form-row">
                        {/* <label>Disabled:</label> */}
                        <div className="form-fieldset">
                            <label>
                                <input
                                    name="disabled"
                                    type="checkbox"
                                    checked={disabled}
                                    onChange={e => {setDisabled(e.target.checked);}}
                                />
                                Disabled
                            </label>
                        </div>
                    </li>
                    <li className="form-row">
                        <div className="button-row">
                            <button type="button" onClick={cancelSubmit}>Cancel</button>
                            <button type="submit" disabled={hasErrors()}>Submit</button>
                        </div>
                    </li>
                    <li className="form-row" />
                </ul>}
            </form>
            <Table columns={columns} data={users} />
        </div>
    )
}

export default UserForm;