import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      this.setState({ hasError: true });
    }
  
    render() {
      if (this.state.hasError) {
        return <div><h1>Uh oh! Something went wrong.</h1><div>{this.state.error}</div></div>;
      }
      return this.props.children;
    }
  }

  export default ErrorBoundary;