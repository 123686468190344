import './Form.css';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from "../context/auth";
import decode from 'jwt-decode';
import axios from 'axios';
import Table from '../components/Table';

const API_URL = process.env.REACT_APP_API_URL;

function AgencyForm() {

    // const [internalServerError, setInternalServerError] = useState(false);
    const { authTokens, setAuthTokens } = useAuth();
    //const [agency, setAgency] = useState(null);
    const [agencies, setAgencies] = useState([]);
    // const [formTitle, setFormTitle] = useState("Add New Agency");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [agencyId, setAgencyId] = useState("");
    const [agencyName, setAgencyName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [isTouchedAgencyName, setisTouchedAgencyName] = useState(false);
    const [isTouchedAddress, setisTouchedAddress] = useState(false);
    const [isTouchedCity, setisTouchedCity] = useState(false);
    const [isTouchedState, setisTouchedState] = useState(false);
    const [isTouchedZipCode, setisTouchedZipCode] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [formHeight, setFormHeight] = useState(50);
    const [isLocked, setIsLocked] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [accordianDirection, setAccordianDirection] = useState(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    
    const useMountEffect = (fun) => useEffect((fun), []);

    const columns = [
        {
            Header: '',
            accessor: 'edit',
            Cell: ({row}) => (
            <div>{!isLocked && <button onClick={() => { setIsEdit(true); mapAgency(row.original); }}>Edit</button>}</div>
            )
        },
        // {
        //     Header: 'Agency Code',
        //     accessor: 'agencyCode'
        // },
        {
            Header: 'Agency Name',
            accessor: 'agencyName'
        },
        {
            Header: 'Address',
            accessor: 'address'
        },
        {
            Header: 'City',
            accessor: 'city'
        },
        {
            Header: 'State',
            accessor: 'state'
        },
        {
            Header: 'Zip Code',
            accessor: 'zipCode'
        },
        {
            id: 'disabled',
            Header: 'Disabled',
            accessor: 'disabled',
            Cell: ({row}) => (
                <div className='center'>{row.original.disabled ? 'x' : ''}</div>
            )
        },
    ];

    const dec = decode(authTokens.token).exp;
    const dtn = Date.now() / 1000;

    const formExpandedRef = useRef();
    const formCollapsedStyle = {height: `${formHeight}px`};

    function logOut() {
        setAuthTokens();
        localStorage.clear();
    }

    function clearForm() {
        setIsEdit(false);
        // setFormTitle("Add New Agency");
        setAgencyId("");
        setAgencyName("");
        setisTouchedAgencyName(false);
        setAddress("");
        setisTouchedAddress(false);
        setCity("");
        setisTouchedCity(false);
        setZipCode("");
        setisTouchedZipCode(false);
        setState("");
        setisTouchedState(false);
        setDisabled(false);
    }   
    
    function shouldMarkError(hasError, isTouched) {
        return hasError ? isTouched : false;
    };

    function agencyCodeExists(agencyCode) {
        var existingCode = agencies.filter(agency => {
            return agency.agencyCode.toString() === agencyCode.toString();
        }).map(function(a){
            return a.agencyCode.toString();
        });

        if(existingCode.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    function agencyNameExists(agencyName) {
        var existingName = agencies.filter(agency => {
            return agency.agencyName === agencyName;
        }).map(function(a){
            return a.agencyName;
        });

        if(existingName.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    function validateForm(agencyName, address, city, state, zipCode) {

        var agencyNameError;
        var agencyNameIsError = false;
        if(agencyName.length === 0) {
            agencyNameError = "Agency Name is required";
            agencyNameIsError = true;
        }
        else if(!isEdit && agencyNameExists(agencyName)) {
            agencyNameError = "Agency Name exists";
            agencyNameIsError = true;
        }

        var zipCodeError; 
        var zipCodeIsError = false;
        if(zipCode.length === 0){
            zipCodeError = "Zip Code is required"; 
            zipCodeIsError = true;
        }
        else if(zipCode.length !== 5){
            zipCodeError =  "Zip Code must be 5 digits"; 
            zipCodeIsError = true;
        }

        return {
            agencyName: {isError: agencyNameError, error: agencyNameIsError},
            address: {isError: address.length === 0, error: "*"},
            city: {isError: city.length === 0, error: "*"},
            state: {isError: state.length !== 2, error: "State is required and must be 2 characters"},
            zipCode: {isError: zipCodeIsError, error: zipCodeError},
        }
    };

    const errors = validateForm(agencyName, address, city, state, zipCode);

    function hasErrors()
    {
        return errors["agencyName"].isError || errors["address"].isError || errors["city"].isError || errors["state"].isError || errors["zipCode"].isError;
    }

    function updateAgency() {
        const url = `${API_URL}/agencies/${agencyId}`;

        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        const putData = {
            'agencyName':agencyName,
            'address':address,
            'city':city,
            'state':state,
            'zipCode':zipCode,
            'disabled':disabled
        };
        
        if (dec > dtn) {
            axios.put(url, putData, config).then(response => response.data)
            .then((data) => {
                setIsSuccess(true);
                getAgencies();
                clearForm();
                setTimeout(() => {setIsSuccess(false)}, 5000);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else if (error.request) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
            });
        }
        else {
            logOut();
        }
    }

    function insertAgency() {
        const url = `${API_URL}/agencies`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        const postData = {
            'agencyName':agencyName,
            'address':address,
            'city':city,
            'state':state,
            'zipCode':zipCode,
            'disabled':disabled
        };
        
        if (dec > dtn) {
            axios.post(url, postData, config).then(response => response.data)
            .then((data) => {
                setIsSuccess(true);
                getAgencies();
                clearForm();
                setTimeout(() => {setIsSuccess(false)}, 5000);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    // console.log(error.response.data);
                    setErrorMessage(error.response.data.title);
                }
                else if (error.request) {
                    // console.log(error.request);
                    setErrorMessage(error.request.data.title);
                }
                else {
                    // console.log(error);
                    setErrorMessage(error.message);
                }
            });
        }
        else {
            logOut();
        }
    }

    function cancelSubmit(e){
        e.preventDefault();
        clearError();
        clearForm();
    }

    function submitAgency(e){
        e.preventDefault();
        clearError();
        if (isEdit){
            updateAgency();
        }
        else {
            insertAgency();
        }
    }

    function mapAgency(agency){
        setAgencyId(agency.agencyId);
        setAgencyName(agency.agencyName);
        setAddress(agency.address);
        setCity(agency.city);
        setState(agency.state);
        setZipCode(agency.zipCode);
        setDisabled(agency.disabled);
    }

    function getAgencies(agencyId) {
        const endpoint = agencyId ? '/' + agencyId : '?includeDisabled=true';
        const url = `${API_URL}/agencies${endpoint}`;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + authTokens.token
            }
        };
        if(dec > dtn) {
            axios.get(url, config).then(response => response.data)
                .then((data) => {
                    if (agencyId) {
                        mapAgency(data);
                        // setFormTitle("Edit Agency");
                        if (!showForm){
                            toggleForm();
                        }
                    }
                    else {
                        setAgencies(data);
                        // setFormTitle("Add New Agency");
                    }
                }).catch(error => {
                    setIsError(true);
                    if (error.response) {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                    else if (error.request) {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                    else {
                        console.log(error.stack);
                        setErrorMessage(error.message);
                    }
                });
        }
        else {
            logOut();
        }
    }

    function clearError() {
        setIsError(false);
        setErrorMessage("");
    }

    function toggleForm() {
        if(showForm){
            setFormHeight(formExpandedRef.current.clientHeight);
        }
        showForm ? setShowForm(false) : setShowForm(true);
        showForm ? setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-right']} />) : setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    }   

    useMountEffect(getAgencies);

    return (
        <div>
            {isError && <div className="error-message white-background">{errorMessage}</div>}
            {!isError && <div className="full-row">{errorMessage}</div>}
            {!isLocked &&
            <form className="form" onSubmit={submitAgency}>
                {!showForm &&
                <ul className="form-wrapper-collapsed" style={formCollapsedStyle}>
                    <li className="form-header-collapsed">
                        <label>&nbsp;</label>
                        <div className="accordian" onClick={toggleForm}>{accordianDirection}</div>
                    </li>
                    <li className="form-row-collapsed">
                    </li>
                </ul>
                }
                {showForm && 
                <ul className="form-wrapper" ref={node => formExpandedRef.current = node}>
                    <li className="form-header">
                        {isEdit && <label>Edit Agency</label>}
                        {!isEdit && <label>Add New Agency</label>}
                        <div className="accordian" onClick={toggleForm}>{accordianDirection}</div>
                    </li>
                    <li className="form-info-message">
                        {isSuccess ? <div className="info-message">Agency successfully submitted!</div> : <div className="infoMessage">&nbsp;</div>}
                    </li>
                    {/* <li className="form-row">
                        <label>Agency Code:</label>
                        <div className="form-fieldset">
                            <input
                                name="agencyCode"
                                disabled={isEdit}
                                className={shouldMarkError(errors["agencyCode"].IsError, isTouchedAgencyCode) ? "validation-error" : ""}
                                onBlur={() => setisTouchedAgencyCode(true)}
                                type="number"
                                maxLength="3"
                                value={agencyCode}
                                onChange={e => {setAgencyCode(e.target.value);}}
                                placeholder="Agency Code"
                            />
                            {shouldMarkError(errors["agencyCode"].isError, isTouchedAgencyCode) ? <div className="error-message">{errors["agencyCode"].error}</div> : agencyCodeError ? <div className="error-message">Agency Code already exists</div> : <div className="error-message"></div>}
                        </div>
                    </li> */}
                    <li className="form-row">
                        {/* <label>Agency Name:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="agencyName"
                                className={shouldMarkError(errors["agencyName"].isError, isTouchedAgencyName) ? "validation-error" : ""}
                                onBlur={() => setisTouchedAgencyName(true)}
                                type="text"
                                maxLength="100"
                                value={agencyName}
                                onChange={e => {setAgencyName(e.target.value);}}
                                placeholder="Agency Name"
                            />
                            {shouldMarkError(errors["agencyName"].isError, isTouchedAgencyName) ? <div className="error-message">{errors["agencyName"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Address:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="address"
                                className={shouldMarkError(errors["address"].isError, isTouchedAddress) ? "validation-error" : ""}
                                onBlur={() => setisTouchedAddress(true)}
                                type="text"
                                maxLength="100"
                                value={address}
                                onChange={e => {setAddress(e.target.value);}}
                                placeholder="Address"
                            />
                            {shouldMarkError(errors["address"].isError, isTouchedAddress) ? <div className="error-message">{errors["address"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>City:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="city"
                                className={shouldMarkError(errors["city"].isError, isTouchedCity) ? "validation-error" : ""}
                                onBlur={() => setisTouchedCity(true)}
                                type="text"
                                maxLength="50"
                                value={city}
                                onChange={e => {setCity(e.target.value);}}
                                placeholder="City"
                            />
                            {shouldMarkError(errors["city"].isError, isTouchedCity) ? <div className="error-message">{errors["city"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>State:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="state"
                                className={shouldMarkError(errors["state"].isError, isTouchedState) ? "validation-error" : ""}
                                onBlur={() => setisTouchedState(true)}
                                type="text"
                                maxLength="2"
                                value={state}
                                onChange={e => {setState(e.target.value);}}
                                placeholder="State"
                            />
                            {shouldMarkError(errors["state"].isError, isTouchedState) ? <div className="error-message">{errors["state"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Zip Code:</label> */}
                        <div className="form-fieldset">
                            <input
                                name="zipCode"
                                className={shouldMarkError(errors["zipCode"].isError, isTouchedZipCode) ? "validation-error" : ""}
                                onBlur={() => setisTouchedZipCode(true)}
                                type="number"
                                maxLength="5"
                                value={zipCode}
                                onChange={e => {setZipCode(e.target.value);}}
                                placeholder="Zip Code"
                            />
                            {shouldMarkError(errors["zipCode"].isError, isTouchedZipCode) ? <div className="error-message">{errors["zipCode"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        {/* <label>Disabled:</label> */}
                        <div className="form-fieldset">
                            <label>
                                <input
                                    name="disabled"
                                    type="checkbox"
                                    checked={disabled}
                                    onChange={e => {setDisabled(e.target.checked);}}
                                />
                                Disabled
                            </label>
                        </div>
                    </li>
                    <li className="form-row">
                        <div className="button-row">
                            <button type="button" onClick={cancelSubmit}>Cancel</button>
                            <button type="submit" disabled={hasErrors()}>Submit</button>
                        </div>
                    </li>
                    <li className="form-row" />
                </ul>}
            </form>
            }
            <Table columns={columns} data={agencies} />
        </div>
    )
}

export default AgencyForm;