import './Footer.css';
import React from 'react';
import Logo_RotaryClub from '../images/Logo_RotaryClub_25h.png'

function Footer() {

    return (
        <div className="footer">
            <div className="footer-border">
                <div className="copyright">Copyright © 2024 The Rotary Club of Fort Myers South</div>
                <div className="hosted-by">
                    <a href="#" className="footer-logo-host" >
                        {/* <img src={Logo_RotaryClub} alt="host-logo-here"></img> */}
                    </a>
                    <div>Site hosted by Polaris Advisors, LLC</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;