import "./Admin.css";
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import RecipientForm from '../components/RecipientForm';
import AgencyForm from '../components/AgencyForm';
import UserForm from '../components/UserForm';
import Footer from '../components/Footer';
import ErrorBoundary from '../components/ErrorBoundary';

function Recipients(props) {
    
    const [showRecipients, setShowRecipients] = useState(true);
    const [showAgencies, setShowAgencies] = useState();
    const [showUsers, setShowUsers] = useState();

    // const canEditUsers = (decode(authTokens.token).CanEditUsers === 'True');
    // const canEditAgencies = (decode(authTokens.token).CanEditAgencies === 'True');
    // const canViewRecipients = (decode(authTokens.token).CanViewRecipients === 'True');

    const handleAdminMenuClick = (menuItem) => {
        setShowRecipients(false);
        setShowAgencies(false);
        setShowUsers(false);
        switch(menuItem){
            case "recipients": return setShowRecipients(true);
            case "agencies": return setShowAgencies(true);
            case "users": return setShowUsers(true);
            default: return null;
        }
    }

    return (
        <div className="admin">
            <ErrorBoundary>
                <Navbar props={props} handleMenuClick={handleAdminMenuClick}></Navbar>
                        <div className="backdrop">
                            <div className="header">
                                {!showRecipients && !showAgencies && !showUsers && <h1>Admin</h1>}
                                {showRecipients && <h1>Recipients</h1>}
                                {showAgencies && <h1>Agencies</h1>}
                                {showUsers && <h1>Users</h1>}
                            </div>
                            {showRecipients && <RecipientForm></RecipientForm>}
                            {showAgencies && <AgencyForm></AgencyForm>}
                            {showUsers && <UserForm></UserForm>}
                        </div>
                <Footer></Footer>
            </ErrorBoundary>
        </div>
    )
}

export default Recipients;