import './Table.css';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import { CSVLink } from 'react-csv';

function Table ({ columns, data }) {

    const dataDownload = useRef();
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        prepareRow,
    } = useTable({ columns, data },
        useFilters,
        useSortBy,
        usePagination);


    function downloadData() {
            dataDownload.current.link.click();
        }

    return (
        <>
        <table {...getTableProps()} className="table">
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th className="table-header" {...column.getHeaderProps()}>
                                <div {...column.getSortByToggleProps()}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span className="sort-icon">
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <FontAwesomeIcon icon={['fas', 'angle-down']} />
                                                : <FontAwesomeIcon icon={['fas', 'angle-up']} />
                                            : ''}
                                    </span>
                                </div>
                                {/* <Filter column={column} /> */}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        className="table-row"
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
            <tfoot className="table-footer">
                <tr>
                    <td colSpan={columns.length}>
                            {/* <span>
                                | Go to page:{' '}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                    }}
                                    style={{ width: '100px' }}
                                />
                            </span>{' '} */}
                        <span className="footer-section">
                            Records/page{' '}
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>    
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>{' '}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}
                        <span>
                            Page
                            {' '}
                                {pageIndex + 1} of {pageOptions.length}
                            {' '}
                        </span>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>{' '}
                        <button className="footer-section" onClick={() => downloadData()}>
                            {<FontAwesomeIcon icon={['fas', 'download']}/>}
                        </button>
                        <CSVLink
                            data={data}
                            filename={"ServiceAboveSelfDataDownload"}
                            ref={dataDownload}
                        />
                    </td>
                </tr>
            </tfoot>
        </table>
        
      </>
    )
}

export default Table;