import './Form.css';
import './PublicForm.css';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from "react-input-mask";
import Select from 'react-select';
import axios from 'axios';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';

const API_URL = process.env.REACT_APP_API_URL;

function SubmitRecipientForm() {

    // const [internalServerError, setInternalServerError] = useState(false);
    // const captchaRef = useRef(null)

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [formTitle, setFormTitle] = useState("Submit Recognition");
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [agencies, setAgencies] = useState([]);
    const [agency, setAgency] = useState();
    const [recipientId, setRecipientId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [recognition, setRecognition] = useState("");
    const [submitterName, setSubmitterName] = useState("");
    const [submitterEmail, setSubmitterEmail] = useState("");
    const [submitterPhone, setSubmitterPhone] = useState("");
    const [isTouchedAgency, setIsTouchedAgency] = useState(false);
    const [isTouchedFirstName, setIsTouchedFirstName] = useState(false);
    const [isTouchedlastName, setIsTouchedlastName] = useState(false);
    const [isTouchedrecognition, setIsTouchedRecognition] = useState(false);
    const [isTouchedsubmitterEmail, setIsTouchedSubmitterEmail] = useState(false);
    const [isTouchedsubmitterName, setIsTouchedSubmitterName] = useState(false);
    const [isTouchedsubmitterPhone, setIsTouchedSubmitterPhone] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [formHeight, setFormHeight] = useState(50);
    const [isLocked, setIsLocked] = useState(false);
    const [accordianDirection, setAccordianDirection] = useState(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);

    const useMountEffect = (fun) => useEffect((fun), []);

    const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

    function getAgencies() {
        const url = `${API_URL}/agencies`;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        
        axios.get(url, config).then(response => response.data)
            .then((data) => {
                    const options = data.map(d => ({
                    "value" : d.agencyName,
                    "label" : d.agencyName
                    }))
                    setAgencies(options);
            }).catch(error => {
                setIsError(true);
                if (error.response) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else if (error.request) {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
                else {
                    console.log(error.stack);
                    setErrorMessage(error.message);
                }
            });
    }

    const formExpandedRef = useRef();
    const formCollapsedStyle = {height: `${formHeight}px`};

    function validateForm(agency, firstName, lastName, recognition, submitterName, submitterEmail, submitterPhone) {
        var emailError; 
        var emailIsError = false;
        
        if(submitterEmail.length === 0){
            emailError = "*"; 
            emailIsError = true;
        }
        else if(!validEmail.test(submitterEmail)){
            emailError =  "Invalid email address"; 
            emailIsError = true;
        }

        return {
            agency: {isError: agency == null, error: "*"},
            firstName: {isError: firstName.length === 0, error: "*"},
            lastName: {isError: lastName.length === 0, error: "*"},
            recognition: {isError: recognition.length === 0, error: "*"},
            submitterName: {isError: submitterName.length === 0, error: "*"},
            submitterEmail: {isError: emailIsError, error: emailError},
            submitterPhone: {isError: submitterPhone.replace(/_/g, "").length !== 12, error: "*"}
        }
    };

    function clearForm() {
        setFormTitle("Sumbit Recognition");
        setRecipientId("");
        setAgency(null);
        setIsTouchedAgency(false);
        setFirstName("");
        setIsTouchedFirstName(false);
        setLastName("");
        setIsTouchedlastName(false);
        setRecognition("");
        setIsTouchedRecognition(false);
        setSubmitterName("");
        setIsTouchedSubmitterName(false);
        setSubmitterEmail("");
        setIsTouchedSubmitterEmail(false);
        setSubmitterPhone("");
        setIsTouchedSubmitterPhone(false);
    }   
    
    function shouldMarkError(hasError, isTouched) {
        return hasError ? isTouched : false;
    };

    const errors = validateForm(agency, firstName, lastName, recognition, submitterName, submitterEmail, submitterPhone);

    function hasErrors()
    {
        return errors["agency"].isError || errors["firstName"].isError || errors["lastName"].isError || errors["recognition"].isError || errors["submitterName"].isError || errors["submitterEmail"].isError || errors["submitterPhone"].isError;
    }

    const insertRecipient = useCallback(async (e) => {

        const url = `${API_URL}/recipients`;
        
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        
        const data = {
            'agencyName':agency,
            'firstName':firstName,
            'lastName':lastName,
            'recognition':recognition,
            'submitterName':submitterName,
            'submitterEmail':submitterEmail,
            'submitterPhone':submitterPhone
        };

        axios.post(url, data, config).then(response => response.data)
        .then((data) => {
            setIsSuccess(true);
            clearForm();
            setTimeout(() => {setIsSuccess(false)}, 5000);
        }).catch(error => {
            setIsError(true);
            if (error.response) {
                console.log(error.stack);
                setErrorMessage(error.message);
            }
            else if (error.request) {
                console.log(error.stack);
                setErrorMessage(error.message);
            }
            else {
                console.log(error.stack);
                setErrorMessage(error.message);
            }
        });
    }, [agency, firstName, lastName, recognition, submitterName, submitterEmail, submitterPhone])

    function cancelSubmit(e){
        e.preventDefault();
        clearError();
        clearForm();
    }

    const submitRecipient = useCallback(async (e) => {
        e.preventDefault();
        clearError();

        if (!executeRecaptcha) {
          console.log('ReCaptcha not available');
          return;
        }
    
        const token = await executeRecaptcha('submitRecipient');
        // console.log(token);

        verifyRecaptcha(token)
            .then((data) => {
                // console.log("data %j", data);
                if(data.score > 0.5)
                    insertRecipient();
                else
                    setErrorMessage('Suspicious activity detected. Recipient not submitted');
            });

    }, [executeRecaptcha, insertRecipient]);

    async function verifyRecaptcha(token) {

        const url = `${API_URL}/recipients/recaptcha/${token}`;
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        return axios.get(url, config).then(response => response.data)
        .then((data) => {
            // console.log("data %j", data);
            return data;
        }).catch(error => {
            setIsError(true);
            if (error.response) {
                console.log(error.stack);
                setErrorMessage(error.message);
            }
            else if (error.request) {
                console.log(error.stack);
                setErrorMessage(error.message);
            }
            else {
                console.log(error.stack);
                setErrorMessage(error.message);
            }
            return null;
        });
    }

    function clearError() {
        setIsError(false);
        setErrorMessage("");
    }

    function toggleForm() {
        if(showForm){
            setFormHeight(formExpandedRef.current.clientHeight);
        }
        showForm ? setShowForm(false) : setShowForm(true);
        showForm ? setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-right']} />) : setAccordianDirection(<FontAwesomeIcon icon={['fas', 'angle-double-left']} />);
    }   

    const reactSelectStyles = {
        container: (provided, state) => ({
            ...provided,
            padding: 0,
            height: 'fit-content',
            zIndex: 4,
            border: '1px solid gray',
            flex: 4
        }),
        control: (provided, state) => ({
            ...provided,
            borderWidth: 0,
            minHeight: 'fit-content',
            height: 'fit-content',
            fontSize: '0.85em'
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '2em'
        }),
        input: (provided, state) => ({
            ...provided,
            height: '1.6em'
        })
    };

    // const reactSelectStyles = {
    //     control: base => ({
    //         ...base,
    //         height: '35px',
    //         minHeight:'35px',
    //         borderColor: 'cdccdf',
    //         fontSize: '0.8em',
    //         fontFamily: 'Avenir, Arial, Helvitica, sans-serif',
    //         width: '380px'
    //     }),
    //     menu: (base) => ({
    //         ...base,
    //         height: '35px',
    //         minHeight:'35px',
    //         fontSize: '0.8em',
    //         fontFamily: 'Avenir, Arial, Helvitica, sans-serif',
    //         marginTop: '0px'
    //     }),
    // };

    useMountEffect(getAgencies);
    // useEffect(() => { handleReCaptchaVerify(); }, [handleReCaptchaVerify]);

    return (
        <div>
            {isError && <div className="error-message white-background">{errorMessage}</div>}
            {!isError && <div className="full-row">{errorMessage}</div>}
            <form className="form-public" onSubmit={submitRecipient}>
                <ul className="form-wrapper-public" ref={node => formExpandedRef.current = node}>
                    {/* <li className="form-header-public">
                        <label>{formTitle}</label>
                        <div className="accordian" onClick={toggleForm}>{accordianDirection}</div>
                    </li> */}
                    <li className="form-info-message">
                        {isSuccess ? <div className="info-message">Recognition successfully submitted!</div> : <div className="infoMessage">&nbsp;</div>}
                    </li>
                    <li className="form-row">
                        <label>Agency</label>
                        <div className="form-fieldset">
                            <Select 
                                name="agency"
                                className={shouldMarkError(errors["agency"].isError, isTouchedAgency) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedAgency(true)}
                                onChange={e => {setAgency(e.value);}}
                                options={agencies} 
                                styles={reactSelectStyles}
                                placeholder=""
                            />
                            {shouldMarkError(errors["agency"].isError, isTouchedAgency) ? <div className="error-message">{errors["agency"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        <label>
                            Officer First
                        </label>
                        <div className="form-fieldset">
                            <input
                                name="firstName"
                                className={shouldMarkError(errors["firstName"].isError, isTouchedFirstName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedFirstName(true)}
                                type="text"
                                maxLength="50"
                                value={firstName}
                                onChange={e => {setFirstName(e.target.value);}}
                                placeholder=""
                            />
                            {shouldMarkError(errors["firstName"].isError, isTouchedFirstName) ? <div className="error-message">{errors["firstName"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        <label>
                            Officer Last
                        </label>
                        <div className="form-fieldset">
                            <input
                                name="lastName"
                                className={shouldMarkError(errors["lastName"].isError, isTouchedlastName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedlastName(true)}
                                type="text"
                                maxLength="50"
                                value={lastName}
                                onChange={e => {setLastName(e.target.value);}}
                                placeholder=""
                            />
                            {shouldMarkError(errors["lastName"].isError, isTouchedlastName) ? <div className="error-message">{errors["lastName"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        <label>
                            Recognition
                        </label>
                        <div className="form-fieldset">
                            <textarea
                                name="recognition"
                                className={shouldMarkError(errors["recognition"].isError, isTouchedrecognition) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedRecognition(true)}
                                value={recognition}
                                onChange={e => {setRecognition(e.target.value);}}
                                placeholder="Enter your nomination reasons here..."
                            />
                            {shouldMarkError(errors["recognition"].isError, isTouchedrecognition) ? <div className="error-message">{errors["recognition"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        <label>
                            Your name
                        </label>
                        <div className="form-fieldset">
                            <input
                                name="submitterName"
                                className={shouldMarkError(errors["submitterName"].isError, isTouchedsubmitterName) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedSubmitterName(true)}
                                type="text"
                                maxLength="50"
                                value={submitterName}
                                onChange={e => {setSubmitterName(e.target.value);}}
                                placeholder=""
                            />
                            {shouldMarkError(errors["submitterName"].isError, isTouchedsubmitterName) ? <div className="error-message">{errors["submitterName"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        <label>
                            Your email
                        </label>
                        <div className="form-fieldset">
                            <input
                                name="submitterEmail"
                                className={shouldMarkError(errors["submitterEmail"].isError, isTouchedsubmitterEmail) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedSubmitterEmail(true)}
                                type="text"
                                maxLength="50"
                                value={submitterEmail}
                                onChange={e => {setSubmitterEmail(e.target.value);}}
                                placeholder=""
                            />
                            {shouldMarkError(errors["submitterEmail"].isError, isTouchedsubmitterEmail) ? <div className="error-message">{errors["submitterEmail"].error}</div> : <div className="error-message"></div>}
                        </div>
                    </li>
                    <li className="form-row">
                        <label>
                            Your phone
                        </label>
                        <div className="form-fieldset">
                            <InputMask 
                                name="submitterPhone"
                                className={shouldMarkError(errors["submitterPhone"].isError, isTouchedsubmitterPhone) ? "validation-error" : ""}
                                onBlur={() => setIsTouchedSubmitterPhone(true)}
                                mask="999-999-9999"
                                value={submitterPhone}
                                onChange={e => {setSubmitterPhone(e.target.value);}}
                                required
                                placeholder=""
                            />                            
                            {shouldMarkError(errors["submitterPhone"].isError, isTouchedsubmitterPhone) ? <div className="error-message">{errors["submitterPhone"].error}</div> : <div className="error-message"></div>}                            
                        </div>
                    </li>
                    {/* <li className="form-row">
                        <ReCaptcha
                            className="button-row"
                            sitekey={RECAPTCHA_SITE_KEY} 
                            ref={captchaRef}
                        />
                    </li> */}
                    <li className="form-row">
                        <div className="button-row">
                            <button type="button" onClick={cancelSubmit}>Cancel</button>
                            <button type="submit" disabled={hasErrors()}>Submit</button>
                        </div>
                    </li>
                    <li className="form-row" />
                </ul>
            </form>
        </div>
    )
}

export default SubmitRecipientForm;