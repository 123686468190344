import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faAngleDoubleLeft, 
    faAngleDoubleRight,
    faAngleDown,
    faAngleUp,
    faDownload
    } from '@fortawesome/free-solid-svg-icons';

library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleUp,
    faDownload
  );