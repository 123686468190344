import './LoginForm.css';
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import { useUser } from "../context/user";
import ErrorBoundary from "./ErrorBoundary";
import axios from 'axios';
import decode from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_URL;

function LoginForm(props) {
    const [isError, setIsError] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const { authTokens, setAuthTokens } = useAuth();
    const { setAgencies } = useUser();
    const referer = props.props.location.state ? props.props.location.state.referer : '/admin';

    const dec = authTokens ? decode(authTokens.token).exp : '';
    const dtn = Date.now() / 1000;

    function postLogin(e) {
        e.preventDefault();
        const url = `${API_URL}/login`;
        axios.post(url, {
            userName,
            password
        }).then(result => {
            if (result.status === 200) {
                setAuthTokens(result.data.token);
                setAgencies(result.data.agency);
            } else {
                setIsError(true);
            }
        }).catch(e => {
            setIsError(true);
        });
    }

    if (dec > dtn) {
        return <Redirect to={referer} />;
    }

    return (
        <ErrorBoundary>
            <div className="login-wrapper">
                <form className="login-form" onSubmit={postLogin}>
                    <label>
                        SIGN IN TO YOUR ACCOUNT
                    </label>
                    <input
                        type="username"
                        className=""
                        value={userName}
                        onChange={e => {
                            setUserName(e.target.value);
                        }}
                        placeholder="email"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                        }}
                        placeholder="password"
                    />
                    <button type="submit">Sign In</button>
                    {isError && <div className="error-message">Invalid username or password</div>}
                    {!isError && <div className="error-message">&nbsp;</div>}
                </form>
            </div>
        </ErrorBoundary>
    );
}

export default LoginForm;