import './Navbar.css';
import React, {Fragment} from 'react';
import Media from 'react-media';
import { useAuth } from '../context/auth';
import { useHistory } from "react-router-dom";
import Logo_RotaryFortMyers_Banner from '../images/Logo_RotaryFortMyers_75h.png'
import Logo_RotaryClub_Banner from '../images/Logo_RotaryFortMyers_50h.png'

function Navbar({handleMenuClick, props}) {

    const { authTokens, setAuthTokens } = useAuth();

    const location = props.location.pathname;
 
    function logOut() {
        setAuthTokens();
        localStorage.clear();
    } 

    const history = useHistory();
    function logIn() { 
        let path = '/login'; 
        history.push(path);
    }

    function home() {
        let path = '/';
        history.push(path);
    }

    return (
        <div className="navbar">
            <div className="">
                <Media queries={{
                        small: "(max-width: 599px)",
                        large: "(min-width: 600px)"
                    }}>
                    {matches => (
                        <Fragment>
                            {matches.small && <img src={Logo_RotaryClub_Banner} alt="Rotary Club Logo" />}
                            {matches.large && <img src={Logo_RotaryFortMyers_Banner} alt="Rotary Club of Fort Myers South Logo" />}
                        </Fragment>
                    )}
                </Media>                
            </div>
            <div className="menu-items">
                {authTokens && <div className="menu-item" onClick={() => handleMenuClick("recipients")}>Recipients</div>}
                {authTokens && <div className="menu-divider">|</div>}
                {authTokens && <div className="menu-item" onClick={() => handleMenuClick("agencies")}>Agencies</div>}
                {authTokens && <div className="menu-divider">|</div>}
                {authTokens && <div className="menu-item" onClick={() => handleMenuClick("users")}>Users</div>}
                {authTokens && <div className="menu-divider">|</div>}
                {authTokens && <div className="menu-item" onClick={() => logOut()}>Log Out</div>}
                {!authTokens && location !== '/login' && <div className="menu-item" onClick={() => logIn()}>Log In</div>}
                {!authTokens && location === '/login' && <div className="menu-item" onClick={() => home()}>Home</div>}
            </div>
        </div>
    )
}

export default Navbar;