import React from 'react';
import { Link } from 'react-router-dom';

function InternalServer() {

    return (
        <div>
            <h1>Oops! Something went wrong</h1>
            <div>
                Click <Link to='/admin'>here</Link> to circle back and try again.
            </div>
        </div>

    )
}

export default InternalServer;