import React from 'react';
import { useAuth } from "../context/auth";
import { Redirect } from "react-router-dom";
import decode from 'jwt-decode';
import SubmitRecipientForm from '../components/SubmitRecipientForm';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ErrorBoundary from '../components/ErrorBoundary';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';

function Home(props) {

    const RECAPTCHA_SITE_KEY = '6LdhHiEqAAAAAHj2f9cJ7JqN5t1Sjzwo3g3GiDXP';
    
    const { authTokens } = useAuth();
    const referer = props.location.state ? props.location.state.referer : '/admin';

    const dec = authTokens ? decode(authTokens.token).exp : '';
    const dtn = Date.now() / 1000;

    if (dec > dtn) {
        return <Redirect to={referer} />;
    }

    return (
        <div>
            <ErrorBoundary>
                <Navbar props={props}></Navbar> 
                <div className='center-content'>
                    <div className="backdrop">
                        <div className="header">
                            <h1>Service Above Self</h1>
                            {/* <div className="divider">|</div> */}
                            <h2>Submit nominations for recognition!</h2> 
                        </div>                     

                         <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                            <SubmitRecipientForm></SubmitRecipientForm>
                        </GoogleReCaptchaProvider>
                    </div>    
                </div>
                <Footer></Footer>
            </ErrorBoundary>
        </div>
    )
}

export default Home;