import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {

    return (
        <div>
            <h1>Oops! That page can't be found</h1>
            <div>
                Click <Link to='/admin'>here</Link> to circle back and try again.
            </div>
        </div>

    )
}

export default NotFound;