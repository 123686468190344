import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ErrorBoundary from '../components/ErrorBoundary';
import LoginForm from '../components/LoginForm';

function Login(props) {
    
    return (
        <div>
            <ErrorBoundary>
                <Navbar props={props}></Navbar>
                <div className='center-content'>
                    <div className="backdrop">
                    <div className="header">
                            <h1>Service Above Self</h1>
                        </div>     
                        <LoginForm props={props}></LoginForm>   
                    </div>                
                </div>
                <Footer></Footer>
            </ErrorBoundary>
        </div>
    )
}

export default Login;